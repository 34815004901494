import React, { FC } from 'react';
import cx from 'classnames';

import { Link, graphql } from 'gatsby';
import Icon from 'common/IconCustom';
import { IButton } from './models';
import './Button.scss';

const Button: FC<IButton> = ({
  isLoading,
  to,
  classes,
  variant,
  type,
  children,
  clickHandler,
  ariaLabel,
  activeClassName,
  target,
  iconPrefix,
  iconSuffix,
  ...rest
}) => {
  const buttonCSSClassNames = cx(
    'btn',
    `btn--${variant}`,
    { 'btn--loading': isLoading },
    { [`${classes}`]: classes }
  );

  return to && !isLoading ? (
    target === '_blank' ? (
      <a
        href={to}
        className={buttonCSSClassNames}
        rel="noopener nofollow"
        aria-label={ariaLabel}
        {...{ target, ...rest }}
      >
        {iconPrefix ? <Icon classes="icon__prefix" icon={iconPrefix} /> : null}
        <span className="btn__inner">{children}</span>
        {iconSuffix ? <Icon classes="icon__suffix" icon={iconSuffix} /> : null}
      </a>
    ) : (
      <Link
        {...{ to, activeClassName, ...rest }}
        aria-label={ariaLabel}
        className={buttonCSSClassNames}
      >
        {iconPrefix ? <Icon classes="icon__prefix" icon={iconPrefix} /> : null}
        <span className="btn__inner">{children}</span>
        {iconSuffix ? <Icon classes="icon__suffix" icon={iconSuffix} /> : null}
      </Link>
    )
  ) : (
    <button
      onClick={clickHandler}
      type={type === 'submit' ? 'submit' : 'button'}
      aria-label={ariaLabel}
      className={buttonCSSClassNames}
      {...rest}
    >
      {iconPrefix ? <Icon classes="icon__prefix" icon={iconPrefix} /> : null}
      <span className="btn__inner">{children}</span>
      {iconSuffix ? <Icon classes="icon__suffix" icon={iconSuffix} /> : null}
    </button>
  );
};

export const query = graphql`
  fragment FragmentButton on TButton {
    structure
    properties {
      ariaLabel
      label
    }
  }
`;

export default Button;
