import { GtmEvents } from '@shared/types';
import { isBrowser } from './browser';
import { getCookies } from './cookies';

export type GtmEventProperties = Record<string, string>;

type GtmEventsReturnType = Partial<
  Record<typeof EVENT_TYPES[keyof typeof EVENT_TYPES], GtmEventProperties>
>;

const EVENT_TYPES = {
  PAGE_VIEW: 'page-view',
  SUBMIT_FORM: 'submit-form',
  SUBMIT_SEARCH: 'submit-search',
} as const;

const SEARCH_VALUE_PLACEHOLDER = '%term%';
const FORM_NAME_PLACEHOLDER = '%name%';

const USER_ID_EVENT_PROPERTY = 'userId';
const SHOPIFY_USER_ID_COOKIE = 'shopify_user';

const SIGN_UP_FORM_NAME = 'Sign-up form';
const CONTACT_FORM_NAME = 'Contact form';
const IFRAME_MESSAGE_FORM_SUBMITTED = 'FORM_SUBMITTED';

const getGtmEvents = (gtmEvents: GtmEvents.Events[]): GtmEventsReturnType => {
  const { events } = gtmEvents?.[0]?.properties || {};

  if (!Array.isArray(events)) return {};

  return events.reduce((event, current) => {
    const { type, properties } = current?.properties || {};
    event[type] = {};

    properties?.forEach(({ properties: { key, value } }) => {
      event[type][key] = value;
    });

    return event;
  }, {});
};

const emitGtmEvent = (eventProperties: GtmEventProperties = {}): void => {
  if (!isBrowser() || !Object.keys(eventProperties).length) return;
  if (USER_ID_EVENT_PROPERTY in eventProperties) {
    const shopifyLoggedUserId = getCookies(SHOPIFY_USER_ID_COOKIE);
    if (shopifyLoggedUserId) {
      eventProperties[USER_ID_EVENT_PROPERTY] = shopifyLoggedUserId;
    } else {
      delete eventProperties[USER_ID_EVENT_PROPERTY];
    }
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventProperties);
};

const replacePropertyPlaceholder = (
  eventProperties: GtmEventProperties = {},
  propertyValue: string = '',
  placeholder = SEARCH_VALUE_PLACEHOLDER
): GtmEventProperties => {
  const localProperties = { ...eventProperties };

  Object.entries(localProperties).forEach(([key, value]) => {
    localProperties[key] = value?.replace(placeholder, propertyValue);
  });

  return localProperties;
};

export {
  getGtmEvents,
  emitGtmEvent,
  replacePropertyPlaceholder,
  EVENT_TYPES,
  FORM_NAME_PLACEHOLDER,
  SIGN_UP_FORM_NAME,
  CONTACT_FORM_NAME,
  IFRAME_MESSAGE_FORM_SUBMITTED,
};
