import React, { FC } from 'react';
import { Footer } from '@shared/types/umbraco/compositions';

import Button from 'common/Button';
import { Container } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { triggerCookiePopupOpen } from 'utils/browser';

import './FooterTop.scss';

const FooterTop: FC<Footer.ITopFooter> = ({ links, additionalLinks, logo }) => (
  <div className="footer-top footer-top--dark-theme" data-testid="footer-top">
    <Container fluid>
      <div className="footer-top__grid">
        <div className="footer-top__logo">
          {logo?.[0].properties?.image ? (
            <GatsbyImage image={logo?.[0].properties?.image} className="footer-top__logo-img" />
          ) : null}
        </div>
        {links?.length ? (
          <ul className="footer-top__nav">
            {links.map(({ properties: { link, ariaLabel, openCookiePopup } }) => (
              <li key={link[0].name}>
                <Button
                  variant="link"
                  target={link[0]?.target}
                  ariaLabel={ariaLabel}
                  {...(openCookiePopup
                    ? { clickHandler: triggerCookiePopupOpen() }
                    : { to: link[0].url })}
                >
                  {link[0].name}
                </Button>
              </li>
            ))}
          </ul>
        ) : null}
        <div className="footer-top__rest">
          {additionalLinks?.length ? (
            <ul className="footer-top__nav2">
              {additionalLinks.map(({ properties: { link, ariaLabel } }) => (
                <li key={link[0].name}>
                  <Button
                    variant="link"
                    to={link[0].url}
                    target={link[0]?.target}
                    ariaLabel={ariaLabel}
                  >
                    {link[0].name}
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </Container>
  </div>
);

export default FooterTop;
