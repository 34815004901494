import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Footer as FooterProps } from '@shared/types/umbraco/compositions';
import FooterTop from './components/FooterTop';
import FooterBottom from './components/FooterBottom';
import './Footer.scss';

const Footer: FC<FooterProps.IFooter> = ({
  copyright,
  socialNetworks,
  links,
  additionalLinks,
  logo,
}) => (
  <footer className="footer">
    <FooterTop {...{ links, additionalLinks, logo }} />
    <FooterBottom {...{ copyright, socialNetworks }} />
  </footer>
);

export const query = graphql`
  fragment FragmentFooter on TFooterComposition {
    copyright
    additionalLinks {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
      }
    }
    links {
      properties {
        ariaLabel
        link {
          name
          target
          url
        }
        openCookiePopup
      }
    }
    logo {
      properties {
        ariaLabel
        imageAlt
        link {
          url
        }
        image {
          fallbackUrl
          svg {
            content
          }
        }
      }
    }
    socialNetworks {
      properties {
        ariaLabel
        icon
        link {
          name
          url
          target
        }
      }
    }
  }
`;

export default Footer;
