import React, { FC } from 'react';
import classNames from 'classnames';

import { IconProps } from './models';
import './IconCustom.scss';

const Icon: FC<IconProps> = ({ icon, classes }) => (
  <span className={classNames('icon', classes, { [`icon--${icon}`]: icon })} />
);

export default Icon;
