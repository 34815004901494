import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { ICheckboxField } from './model';

import './CheckboxField.scss';

const CheckboxField: FC<ICheckboxField> = ({
  id,
  name,
  value,
  isRequired,
  labelText,
  collapsedText,
  labelClassName,
  containerClassName,
  collapsedTextClassName,
  checkboxIndicatorClassName,
  handleChange,
}) => {
  const [showCollapsedText, setShowCollapsedText] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const collapsedTextToggler: HTMLButtonElement | null =
      containerRef?.current?.querySelector('[data-toggle]');

    if (collapsedTextToggler) {
      collapsedTextToggler.onclick = (e) => {
        e.preventDefault();
        setShowCollapsedText((prev) => !prev);
      };
    }
  }, []);

  return (
    <div
      className={classNames('checkbox', {
        [`${containerClassName}`]: containerClassName,
      })}
      ref={containerRef}
    >
      <label
        htmlFor={id}
        className={classNames('checkbox__label', {
          [`${labelClassName}`]: labelClassName,
        })}
      >
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={value}
          required={isRequired}
          onChange={handleChange}
        />
        <span
          className={classNames('checkbox__indicator', {
            [`${checkboxIndicatorClassName}`]: checkboxIndicatorClassName,
          })}
        />
        <DangerouslySetInnerHtml
          html={labelText}
          className={classNames({
            'checkbox__collapsed-text--opened': showCollapsedText,
          })}
        />
      </label>

      {showCollapsedText && collapsedText ? (
        <DangerouslySetInnerHtml
          html={collapsedText}
          className={classNames('checkbox__collapsed-text', {
            [`${collapsedTextClassName}`]: collapsedTextClassName,
          })}
        />
      ) : null}
    </div>
  );
};

export default CheckboxField;
